.ThemeToggle-module_theme-toggle__wrapper_3YSmK__Main {
  border-radius: 100px;
  width: 80px;
  min-height: 30px;
}

.ThemeToggle-module_theme-toggle__wrapper--no-mobile_fQ4tK__Main {
  width: 280px;
  display: flex;
}

.ThemeToggle-module_theme-toggle__wrapper--no-desktop_llHxV__Main {
  display: none;
}

.ThemeToggle-module_theme-toggle__content_TWWXb__Main {
  background: var(--buttons-switch-theme-bg);
  cursor: pointer;
  border-radius: 100px;
  justify-content: space-around;
  align-items: center;
  width: 80px;
  min-height: 30px;
  transition: background-color 1s;
  display: flex;
  position: relative;
}

.ThemeToggle-module_theme-toggle__content_TWWXb__Main :after {
  content: "";
  background-color: var(--icons-primary);
  border-radius: 100px;
  width: 40px;
  height: 30px;
  transition: right 1s, background-color 1s;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

[data-theme="dark"] .ThemeToggle-module_theme-toggle__content_TWWXb__Main :after {
  transition: right 1s, background-color 1s;
  right: 40px;
}

.ThemeToggle-module_theme-toggle__light-theme_IHIQX__Main, .ThemeToggle-module_theme-toggle__dark-theme_WZtca__Main {
  align-items: center;
  display: flex;
}

.ThemeToggle-module_theme-toggle__light_kJShw__Main {
  z-index: 1;
  position: relative;
}

.ThemeToggle-module_theme-toggle__light_kJShw__Main path {
  fill: var(--buttons-switch-theme-dark-icon-icon);
}

.ThemeToggle-module_theme-toggle__dark_bAwmd__Main {
  z-index: 1;
  position: relative;
}

.ThemeToggle-module_theme-toggle__dark_bAwmd__Main path {
  fill: var(--buttons-switch-theme-light-icon-icon);
}

@media (max-width: 660px) {
  .ThemeToggle-module_theme-toggle__wrapper--no-mobile_fQ4tK__Main {
    display: none;
  }

  .ThemeToggle-module_theme-toggle__wrapper--no-desktop_llHxV__Main {
    margin: 5px 0 15px 10px;
    display: flex;
  }
}

